<div class="container">
  <div class="row">
    <div class="col-md-12 text-center title-container">
      <h4>Personal</h4>
      <div class="underline"></div>
    </div>
    <div class="col-md-12">
      <p class="break-all">I live in <a href="https://www.fxva.com/" target="_blank">Fairfax</a>, Virginia, a suburb of Washington, DC. I
        was born and raised in <a href="https://www.lonelyplanet.com/india/kolkata-calcutta" target="_blank">Kolkata
        </a>(formerly Calcutta), India, but have since lived in <a
          href="https://www.lonelyplanet.com/india/bengaluru-bangalore" target="_blank">Bangalore</a>, <a
          href="https://www.visitmanchester.com/" target="_blank">Manchester</a>,<a
          href="https://www.visitsingapore.com/en/" target="_blank"> Singapore</a>, <a
          href="https://www.minneapolis.org/" target="_blank">Minneapolis</a>, <a href="https://www.austintexas.org/"
          target="_blank">Austin</a>, and now <a href="https://www.washington.org/"
          target="_blank">DC</a>.</p>
      <p class="break-all">My mother tongue is <a href="https://www.newworldencyclopedia.org/entry/Bengali_language"
          target="_blank">Bengali</a>, which is the <a
          href="https://en.wikipedia.org/wiki/List_of_languages_by_number_of_native_speakers" target="_blank">fifth most
          spoken language by number of native speakers</a> and <a
          href="https://en.wikipedia.org/wiki/List_of_languages_by_total_number_of_speakers" target="_blank">seventh
          most spoken language by total number of speakers</a> in the world.</p>
      <p class="break-all">I enjoy hiking, swimming, reading non-fiction, watching comedy shows, teaching soccer to my kids, wasting time
        on YouTube, pointlessly arguing about meaningless stuff such as whether Federer is better than Nadal and Messi is better than Ronaldo, and
        passionately following <a href="https://www.manutd.com/" target="_blank">Manchester United</a>, and the <a
          href="https://www.vikings.com/" target="_blank">Minnesota Vikings</a>.
      </p>
      <p class="text-center">Below are few random travel pictures.</p>
    </div>
  </div>

  <div class="row image-viewer-container">
    <div class="col-md-2">
      <div class="arrow-container" (click)="previousImage()">
        <div class="left-arrow">
          <img src="assets/images/chevron-left-icon.svg">
        </div>
      </div>
    </div>
    <div class="col-md-8 height-full">
      <div class="img-viewer height-full text-center">
        <img [src]="getSelectedImage()" class="height-full">
      </div>
      <div class="img-caption" *ngIf="getSelectedCaption()">
          {{getSelectedCaption()}}
      </div>
    </div>
    <div class="col-md-2">
      <div class="arrow-container" (click)="nextImage()">
        <div class="arrow">
          <img src="assets/images/chevron-right-icon.svg">
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 image-set">
      <ng-container *ngFor="let image of allImages; let i = index;">
        <div class="img-holder" (click)="selectImage(i)">
          <img [src]="image.imgUrl">
        </div>
      </ng-container>
    </div>
  </div>
</div>