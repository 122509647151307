<div class="container">
  <div class="row">
    <div class="col-md-12 text-center title-container">
      <h4>Home</h4>
      <div class="underline"></div>
    </div>
    <div class="col-md-8">
      <p class="break-all">I am a Professor of Information Systems at the <a
          href="http://business.gmu.edu/" target="_blank">Costello College of Business</a> at <a href="https://www2.gmu.edu/"
          target="_blank"> George Mason
          University</a>. I also serve as the Chair of the <a href="https://business.gmu.edu/faculty-and-research/academic-areas/information-systems-and-operations-management-area"
          target="_blank">Information Systems and Operations Management </a> <b>(ISOM)</b> Area. Previously, I served as the Director of MBA
          Programs for three years. </p>
      <p class="break-all">I received my Ph.D. in Information and Decision Sciences, and MBA in Finance and Management of Information Systems (MIS) from the <a
          href="https://carlsonschool.umn.edu/" target="_blank">Carlson School of Management</a> at <a
          href="https://twin-cities.umn.edu/" target="_blank">University of Minnesota</a>. Before that, I studied
        Computer Science 
        at the <a href="https://www.iiests.ac.in/" target="_blank">Indian Institutes of Engineering Science
          and
          Technology</a>.</p>

      <p class="break-all">My research focuses on the design and economics of information systems. I teach Data Analytics, Artificial Intelligence, Digital Transformation, and
        Management of IT to undergradute and graduate students, as well as business executives. </p>

        <p class="break-all">I have been serving as an Associate Editor of <a href="https://pubsonline.informs.org/journal/isre"
        target="_blank">Information Systems
        Research </a><b>(ISR)</b> since 2018. Additionally, I am an Editorial Review Board Member at the <a href="https://aisel.aisnet.org/jais"
        target="_blank">Journal of the Association for Information Systems </a><b>(JAIS)</b>, where I previously held the role of Associate Editor for three years.</p>

    </div>
    <div class="col-md-4 img-holder">
      <img src="assets/images/HOME.jpg">
    </div>
  </div>
</div>

<div class="fluid-container interests">
  <div class="row m-0">
    <div class="col-md-6 text-center title-container">
      <h4>Areas of Expertise</h4>
      <div class="underline"></div>
    </div>
    <div class="col-md-6 text-center title-container education">
      <h4>Contact Information</h4>
      <div class="underline"></div>
    </div>
    <div class="col-md-6">
      <div class="row">
        <div class="col-md-12 text-center">Human-AI Interaction</div>
        <div class="col-md-12 text-center">Information Security </div>
        <div class="col-md-12 text-center">Innovation Platforms</div>
        <div class="col-md-12 text-center">Machine Learning</div>
        <div class="col-md-12 text-center">Behavioral Economics</div>
        <div class="col-md-12 text-center">Laboratory Experiments</div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="row m-0 height-full">
        <div class="col-md-12 text-center"> 
          <p>Enterprise Hall 148 <p>
                      <p>4400 University Drive </p>

                      <p>Fairfax, VA 22030 </p>

                      <p>Mailstop: 5F4 </p>

                      <p>Email: <a href="mailto:psanyal@gmu.edu">psanyal@gmu.edu</a> </p>
                    </div>
       
      </div>
    </div>
  </div>
</div>