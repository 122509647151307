<div class="container">
  <div class="row">
    <div class="col-md-12 text-center title-container">
      <h4>Awards</h4>
      <div class="underline"></div>
    </div>
    <div class="col-md-8 break-all">
      <ul>
        <li><span class="text-underline">MSBA Faculty of the Year Award</span>, Costello College of Business, George Mason University, 2024.</li>
        <li><span class="text-underline">MBA Faculty of the Year Award</span>, Costello College of Business, George Mason University, 2023.</li>
        <li><span class="text-underline">Dean’s Scholar Award</span>, Costello College of Business, George Mason University, 2017-2022.</li>
        <li><span class="text-underline">Associate Editor of the Year Award</span>, Information Systems Research (ISR), 2020.</li>
        <li><span class="text-underline">Outstanding Professor in Information Systems and Operations Management</span> (ISOM) Teaching Award, Costello College of Business, 
        George Mason University, 2018.</li>
        <li><span class="text-underline">Faculty Award for Outstanding Research and Publication</span>, Costello College of Business, George Mason University, 2022, 2021, 2018,
          2017, 2015, 2014, 2013, 2012.</li>
        <li><span class="text-underline">Faculty Award for Outstanding Service</span>, Costello College of Business, George Mason University, 2020, 2019, 2018, 2016, 2015.</li>
        <li><span class="text-underline">Executive MBA Outstanding Faculty Award</span>, Costello College of Business, George Mason University, 2016.</li>
        <li><span class="text-underline">George Mason University Teaching Excellence Award</span>, George Mason University, 2014. </li>
        <li><span class="text-underline">Best Reviewer Award</span>, Information Systems Society and Information Systems Research (ISR), 2011.</li>
        <li><span class="text-underline">Design Science Research Award</span> (with G. Adomavicius, S. P. Curley, and A. Gupta), INFORMS Information Systems
          Society, 2011.</li>
        <li><span class="text-underline">Outstanding Ph.D. Student Teaching Award</span>, Carlson School of Management, University of Minnesota, 2008, and
          2007.</li>
        <li>Nominated for <span class="text-underline">Best Paper Award</span> at the Annual Workshop on Information Technology and Systems (WITS), 2007,
          and
          2006.</li>
        <li><span class="text-underline">Graduate School Doctoral Dissertation Fellowship</span>, University of Minnesota, 2007.</li>
        <li><span class="text-underline">Carlson School Doctoral Dissertation Fellowship</span>, Carlson School of Management, University of Minnesota,
          2007.
        </li>
      </ul>
    </div>
    <div class="col-md-4">
        <img src="assets/images/awards.jpg">
        <div class="img-caption">
            Receiving the University Teaching Excellence Award, George Mason University, 2014
        </div>
    </div>
  </div>
</div>