<div class="container">
  <div class="row">
    <div class="col-md-12 text-center title-container">
      <h4>Research</h4>
      <div class="underline"></div>
    </div>
    <div class="col-md-8 break-all">
      My research focuses on understanding how people interact with information systems (IS), and how their design
      influences
      the nature of the interaction leading to differences in outcomes — both personal and societal. I study these issues
      in the
      contexts of human-AI interactions, information security, crowdsourcing platforms, and electronic markets. I draw upon theories from
      economics, design science, and cognitive psychology, and utilize machine learning, econometrics, and experimental
      methods.
      <br><br>
      My research has been published in <b>Management Science</b>, <b>MIS Quarterly</b>, <b>Information Systems Research (ISR)</b>, the
      <b>Journal of
      Management Information Systems (JMIS)</b>, <b>Production and Operations Management (POM)</b>, and the <b>Journal of Operations Management (JOM)</b> among other outlets. My
      studies on designing continuous combinatorial auctions (CoCoA) was awarded the Design Science Research Award by
      the
      INFORMS Information Systems Society.
      <br><br>
      In the IS field, I was among the first to use experimental and behavioral economics in my
      studies. Since 2005, I have conducted many laboratory experiments with hundreds of subjects to better understand how people use technology to make decisions in complex environments.
      My commentary on Economic Experiments in IS, coauthored with Gupta, A., and Kannan, K., published in MIS
      Quarterly, can
      be found <a href="https://misq.org/economic-experiments-in-information-systems.html" target="_blank">here</a>.
      <br><br>
      In 2018, I organized the <a href="http://2018.webeis.org/" target="_blank">Fourth Workshop on Experimental and Behavioral Economics in Information Systems</a> (WEBEIS) at George Mason.
    </div>
    <div class="col-md-4">
      <img src="assets/images/research.JPG">
      <div class="img-caption">
        With Nobel Laureate, Prof. Vernon Smith, 2007
      </div>
    </div>
  </div>
</div>