import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ResearchComponent } from './research/research.component';
import { TeachingComponent } from './teaching/teaching.component';
import { ServiceComponent } from './service/service.component';
import { AwardsComponent } from './awards/awards.component';
import { PersonalComponent } from './personal/personal.component';

const routes: Routes = [
    { path: '', redirectTo: 'home', pathMatch: 'full' },
    { path: 'home', component: HomeComponent },
    { path: 'research', component: ResearchComponent },
    { path: 'teaching', component: TeachingComponent },
    { path: 'service', component: ServiceComponent },
    { path: 'awards', component: AwardsComponent },
    { path: 'personal', component: PersonalComponent }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }