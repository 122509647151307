import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-personal',
  templateUrl: './personal.component.html',
  styleUrls: ['./personal.component.scss']
})
export class PersonalComponent implements OnInit {

  allImages = [
    {
      imgUrl: 'assets/images/personal/1.JPG',
      imgCaption: 'Wembley Stadium, London'
    }, {
      imgUrl: 'assets/images/personal/2.jpg',
      imgCaption: 'Allianz Arena, Munich'
    }, {
      imgUrl: 'assets/images/personal/3.JPG',
      imgCaption: 'Vatican City'
    }, {
      imgUrl: 'assets/images/personal/4.JPG',
      imgCaption: 'Colosseum, Rome'
    }, {
      imgUrl: 'assets/images/personal/5.JPG',
      imgCaption: 'One of the many canals in Amsterdam'
    }, {
      imgUrl: 'assets/images/personal/6.JPG',
      imgCaption: 'St. Peter\'s Piazza from the top of St. Peter\'s Basilica'
    }, {
      imgUrl: 'assets/images/personal/7.jpg',
      imgCaption: 'Old Town Square, Prague'
    }, {
      imgUrl: 'assets/images/personal/8.jpg',
      imgCaption: 'Wenceslas Square, Prague'
    }, {
      imgUrl: 'assets/images/personal/9.jpg',
      imgCaption: 'Maria Skłodowska-Curie Museum, Warsaw'
    }, {
      imgUrl: 'assets/images/personal/10.jpg',
      imgCaption: 'Tower Bridge, London'
    }, {
      imgUrl: 'assets/images/personal/11.JPG',
      imgCaption: 'Atop Milan Cathedral, Milan'
    }, {
      imgUrl: 'assets/images/personal/12.jpg',
      imgCaption: 'Guinness Storehouse, Dublin'
    }, {
      imgUrl: 'assets/images/personal/13.jpg',
      imgCaption: 'Cliffs at Giants Causeway, Ireland'
    }, {
      imgUrl: 'assets/images/personal/14.jpg',
      imgCaption: 'Desert Safari, Dubai'
    }, {
      imgUrl: 'assets/images/personal/15.jpg',
      imgCaption: 'North Korea in the background, Korean Demilitarized Zone (DMZ)'
    }, {
      imgUrl: 'assets/images/personal/16.jpg',
      imgCaption: 'Joint Security Area, Korean Demilitarized Zone (DMZ)'
    }, {
      imgUrl: 'assets/images/personal/17.jpg',
      imgCaption: 'Soccer City, Johannesburg'
    }, {
      imgUrl: 'assets/images/personal/18.jpg',
      imgCaption: 'Table Top Mountain in the background, Cape Town'
    }, {
      imgUrl: 'assets/images/personal/19.jpg',
      imgCaption: 'Face painting in South Africa'
    }, {
      imgUrl: 'assets/images/personal/21.jpg',
      imgCaption: 'Taj Mahal, Agra'
    }, {
      imgUrl: 'assets/images/personal/22.jpg',
      imgCaption: 'View of Taj Mahal from Agra Fort, India'
    }, {
      imgUrl: 'assets/images/personal/23.jpg',
      imgCaption: 'Konark Sun Temple, India'
    }, {
      imgUrl: 'assets/images/personal/24.JPG',
      imgCaption: 'Shanghai, China'
    }, {
      imgUrl: 'assets/images/personal/25.jpg',
      imgCaption: 'Rotterdam Harbor, The Netherlands'
    }, {
      imgUrl: 'assets/images/personal/26.jpg',
      imgCaption: 'Auckland, New Zealand'
    }
  ];
  selectedIndex = 0;
  constructor() {
    this.allImages = this.allImages.sort(() => {
      return .5 - Math.random();
    });
  }

  ngOnInit() {
  }

  getSelectedImage() {
    return this.selectedIndex ? this.allImages[this.selectedIndex].imgUrl : this.allImages[0].imgUrl;
  }

  getSelectedCaption() {
    return this.selectedIndex ? this.allImages[this.selectedIndex].imgCaption : this.allImages[0].imgCaption;
  }

  selectImage(index: number) {
    this.selectedIndex = index;
  }

  previousImage() {
    this.selectedIndex = this.selectedIndex > 0 ? --this.selectedIndex : this.allImages.length - 1;
  }

  nextImage() {
    this.selectedIndex = this.selectedIndex < this.allImages.length - 1 ? ++this.selectedIndex : 0;
  }

}
