<div class="container">
  <div class="row">
    <div class="col-md-12 text-center title-container">
      <h4>Teaching</h4>
      <div class="underline"></div>
    </div>
    <div class="col-md-8 break-all">
      I teach courses related to the management of information systems (MIS) in organizations. More specifically, I have taught the following courses: Business Analytics, 
      Management of IT, AI-enabled Digital Transformation, Data Mining, Database Design, Networks and Security, Programming, IT Infrastructure Management, and Global Business. 
      <br><br> 
      I have received many teaching awards, including a <b>University Teaching Excellence Award</b> at George Mason in 2014.
      <br><br>
      I have been using <a href="https://rapidminer.com/products/studio/" target="_blank">RapidMiner Studio</a> in some of my Analytics courses for many years. A playlist
      containing my tutorial videos can be found <a href="https://www.youtube.com/playlist?list=PL8l7AbNQ9MfQWIwifBuCFbw8PYwur5znc" target="_blank">here
        </a>.
        <br><br>
        If you are looking for datasets for use in your machine learning classes, a great list is available <a href="https://www.kdnuggets.com/datasets/index.html" target="_blank">here</a>.
    </div>
    <div class="col-md-4 img-holder">
        
            <img src="assets/images/TeachingPage.jpg">
           
    </div>
  </div>
</div>