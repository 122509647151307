<div class="container">
  <div class="row">
    <div class="col-md-12 text-center title-container">
      <h4>Service</h4>
      <div class="underline"></div>
    </div>
    <div class="col-md-6 font-medium">
      <h5>Major Services to Profession</h5>
      <ul class="main-list break-all">
        <li>Associate Editor (Journal)
          <ul>
            <li>Information Systems Research (ISR), 2018-</li>
            <li>Journal of the Association for Information Systems (JAIS), 2019-2023</li>
          </ul>
        </li>
          <li>Special Issue Editor
            <ul>
              <li>Generative AI - Decision Support Systems (DSS), 2024-</li>
            </ul>
          </li>
          <li>Workshop Organizer
          <ul>
            <li>The Fourth Virginia Research Seminar Series in Information Systems (VRSS), 2019, George Mason
              University, Arlington, VA</li>
            <li>The Fourth Workshop on Experimental and Behavioral Economics in Information Systems (WEBEIS), 2018,
              George Mason University, Arlington, VA</li>
          </ul>
        </li>
        
        <li>Organizing Committee Member
        <ul>
          <li>Workshop on Information Technology and Systems (WITS): 2023, 2020, 2015 </li>
          <li>AMCIS Doctoral Consortium: 2022 </li>
        </ul>
      </li>

        <li>Program Committee Member
          <ul>
            <li>Workshop on Information Technology and Systems (WITS): 2010-</li>
            <li>INFORMS Conference on Information Systems and Technology (CIST): 2010-</li>

          </ul>
        </li>

      </ul>
      <h5>Major Services to Institution</h5>
      <ul class="main-list-university break-all">
        <li>George Mason University
          <ul>
            <li>University
              <ol>
                <li>Distinguished Mentoring Fellow, 2020-21</li>
                <li>Member: Teaching Excellence Awards Selection Committee, 2018, 2017, 2016</li>
                <li>Member: Technology Policy Committee, 2012-2014</li>
              </ol>
            </li>
            <li>College of Business
              <ol>
                <li>Director: MBA Programs and Graduate Certificates, 2018-2021.</li>
                <li>Co-Chair: PhD Program Design Committee, 2016-2021.</li>
                <li>Chair: Graduate Policy Committee, 2016-2018.</li>
                <li>Member: Undergraduate Curriculum Revision Task Force, 2014.</li>
              </ol>
            </li>
            <li>Information Systems and Operations Management (ISOM) Area
              <ol>
                  <li>Chair: MIS Tenure-track Faculty Recruiting Committee, 2020, 2019, 2018</li>
                  <li>Chair: MIS Term Faculty Recruiting Committee, 2019.</li>
                  <li>Member: Tenure-track Faculty Recruiting Committee, 2014, 2011, 2010.</li>
              </ol>
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <div class="col-md-6">
      <div class="row">
        <div class="col-md-12">
          <img src="assets/images/service_background.jpg">
          <div class="img-caption">
        
                  </div>
              </div>

    </div>  
  </div>
</div>