<div class="fluid-container">
  <div class="row title m-0">
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-12 image-holder">
          <img src="assets/images/mason-logo.png">
        </div>
        <div class="col-md-12 professor-title">
          Pallab Sanyal, PhD
          <p>PROFESSOR, AREA CHAIR</p>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container">
  <div class="row navbar-custom">
    <div class="col-md-12 p-0">
      <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav mr-auto">
            <li class="nav-item" routerLink="home" routerLinkActive="active">
              <a class="nav-link">Home <span class="sr-only">(current)</span></a>
            </li>
            <li class="nav-item" routerLink="research" routerLinkActive="active">
              <a class="nav-link">Research</a>
            </li>
            <li class="nav-item" routerLink="teaching" routerLinkActive="active">
              <a class="nav-link">Teaching</a>
            </li>
            <li class="nav-item" routerLink="service" routerLinkActive="active">
              <a class="nav-link">Service</a>
            </li>
            <li class="nav-item" routerLink="awards" routerLinkActive="active">
              <a class="nav-link">Awards</a>
            </li>
            <li class="nav-item" routerLink="personal" routerLinkActive="active">
              <a class="nav-link">Personal</a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </div>
</div>

<router-outlet></router-outlet>

<div class="fluid-container footer-container">
  <div class="row footer">
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-4 img-holder">
          <a href="http://business.gmu.edu/"><img src="assets/images/gmu_business_logo.png" class="university-logo"></a>
        </div>
        <div class="col-md-4"></div>
        <div class="col-md-4 text-center">
          <a href="https://www.linkedin.com/in/pallab-sanyal-3a42462"><img src="assets/images/linkedin-icon.svg"
              class="icon-group"></a>
          <a href="https://business.gmu.edu/profiles/psanyal"><img
              src="assets/images/university-icon.svg" class="icon-group"></a>
          <a href="mailto:psanyal@gmu.edu"><img src="assets/images/envelope-icon.svg" class="icon-group"></a>
        </div>
      </div>
    </div>
  </div>
</div>